import React from 'react';
import './PopupText.css';

class PopupText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        console.log("did Mount");
    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    render() {

        
        return(
            <div className = "PopupText Popup-Box-Big">
                <div className = "PopupBox-Title"> { this.props.title } </div>
                <div> { this.props.text }  </div>
            </div>
        )
    }
}

export default PopupText;