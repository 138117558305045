import React from 'react';
import './Login.css';

import { jwtDecode } from 'jwt-decode';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: this.props.activateLogin,
        };

        this.loginNameInput = React.createRef();
        this.loginPasswordInput = React.createRef();
        this.loginButton = React.createRef();

        this.login = this.login.bind(this);
        this.checkLoginEnter = this.checkLoginEnter.bind(this);
        this.checkLoginInputs = this.checkLoginInputs.bind(this);
    }

    componentDidMount() {
        console.log("did Mount");


        const tokenCookie = (() => {
            const name = "serviceToken=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(';');
      
            for(let cookie of cookieArray) {
              while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
              }
              if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
              }
            }
      
            // return null if the cookie is not found
            return null;
        })();



        if(tokenCookie !== null) {
            let token = jwtDecode(tokenCookie);
            
            //console.error(token);
            //console.error(sessionStorage.getItem("token"));
            
            if(new Date(token.exp) >= Math.floor(Date.now() / 1000)) {
                this.props.setloggedIn(true);
            };
        }

    
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        const user = urlParams.get('user'); 

        if(user === "demo") {
            this.loginNameInput.current.value = "Demo2";
            this.loginPasswordInput.current.value = "Sensor1234";
            this.login();
        }        
        
        /*function doesHttpOnlyCookieExist(cookiename) {
            var d = new Date();
            d.setTime(d.getTime() + (1000));
            //var expires = "expires=" + d.toUTCString();
          
            //document.cookie = cookiename + "=new_value;path=/;" + expires;
            document.cookie = cookiename + "=new_value;path=/;";
            return document.cookie.indexOf(cookiename + '=') == -1;
          }
          
        */
    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");
        
        return ({ 
            loggedIn: nextProps.activateLogin
        })
    }

    async login() {

        let data = {
            "username": this.loginNameInput.current.value,
            "password": this.loginPasswordInput.current.value,
        };

        if(this.loginNameInput.current.value !== "" && this.loginPasswordInput.current.value !== "") {

            await fetch('https://effy.tecomon.net/API/SECURE/', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                //mode: "cors", // no-cors, *cors, same-origin
                //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    //'Authorization': 'Basic '+btoa('test:test'), 
                },
                //redirect: "follow", // manual, *follow, error
                //referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            })
            .then(res => { 
                //console.error(res.status);

                if(res.status !== 200) { 
                    throw new Error("Login failed");
                } else {
                    return res;
                }
            })
            .then(res => res.json())
            .then(json => {
                if(json.login === true) {
                    this.loginButton.current.style.backgroundColor = "var(--color-green-light)";

                    //localStorage.setItem("token", json.jwt);

                    this.props.setloggedIn(true);
                    this.setState({ loggedIn: true});


                    window.scroll({
                        top: "100vh",
                        left: 0,
                        //behavior: "smooth", // setted in css file
                    });


                    //set cookie for access of service
                    let currentDate = new Date();
                    let expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
                    
                    if(process.env.NODE_ENV === 'production') {
                        document.cookie = "serviceToken="+json.jwt+"; expires="+expirationDate+"; domain=.tecomon.net; path=/";
                    } else {
                        document.cookie = "serviceToken="+json.jwt+"; expires="+expirationDate+"; domain=localhost; path=/";
                    }


                    console.log("Login successful");
                } else {
                    throw new Error("Login failed");
                }
            })
            .catch (error => {
                console.error(error);
                
                
                this.loginButton.current.style.backgroundColor = "red";

                this.loginNameInput.current.value = "";
                this.loginPasswordInput.current.value = "";
            });
        }
    }

    checkLoginEnter(e) {
        if(e.key === "Enter") {
            this.checkLoginInputs();
        }
    }

    checkLoginInputs() {
        if(this.loginNameInput.current.value !== "" && this.loginPasswordInput.current.value !== "") {
            this.login();
        }  
        
        if (this.loginPasswordInput.current.value === "") {
            this.loginPasswordInput.current.focus();
        }
        if (this.loginNameInput.current.value === "") {
            this.loginNameInput.current.focus();
        } 
    }


    render() {
        if(this.state.loggedIn === false) {

            return(
                <div className = "Login">
                    <div className = "Login-Form">
                        <h2>Login</h2>
                        <div className = "Login-Form-Input">
                            <input ref = {this.loginNameInput} onKeyDown = { this.checkLoginEnter } type = "text" placeholder = "Username" />
                            <input ref ={ this.loginPasswordInput} onKeyDown = { this.checkLoginEnter } type = "password" placeholder = "Password" /> 
                        </div>
                        <div ref ={ this.loginButton } className = "button Login-Button" onClick = { this.checkLoginInputs }>Login</div>
                        {/*<div className='button Demo-Button forgot-password'>Forgot Password?</div>*/}
                        
                        <div className = "button Demo-Button" onClick = { () => window.location.href = "/?user=demo" }>Demo</div>
                        {/*<div className = "button Login-Button" onClick = { () => window.open("https://spaas.tecomon.net/members/register.php", "_blank") }>Register</div>*/}

                    </div>
                </div>
            )
        }
    }
}

export default Login;