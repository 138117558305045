import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.css';

import Header from './Header.js'
import Footer from './Footer.js';
import Main from './Main.js'
/*import Gateways from './Gateways.js';
import Devices from './Devices.js';
import Services from './Services.js';
import Account from './Account.js';*/


  function App() {
    

    const [loggedIn, setloggedIn] = useState(false);
    
    return (
      <div className="App">
        <Header activateHeader={loggedIn} setloggedIn={setloggedIn} />

        <Routes>
          <Route path="/" element={ <Main loggedIn={loggedIn} setloggedIn={setloggedIn} /> } />
          {/*<Route path="/account" element={ <Account /> } />
          <Route path="/gateways" element={ <Gateways /> } />
          <Route path="/devices" element={ <Devices /> } />
          <Route path="/services" element={ <Services /> } />*/}
        </Routes>

        <Footer />

      </div>
    );
  }

export default App;