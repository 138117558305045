import React from 'react';
import './Popup.css';

import PopupDevice from './PopupDevice.js'
import PopupGateway from './PopupGateway.js'
import PopupService from './PopupService.js'
import PopupAddService from './PopupAddService.js'
import PopupAddGateway from './PopupAddGateway.js'
import PopupAddDevice from './PopupAddDevice.js'

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.popupRef = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        console.log("did Mount");

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        console.log("will Unmount");

        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }


    handleOutsideClick(event) {
        if (this.popupRef && !this.popupRef.current.contains(event.target)) {
            this.props.deactivate();
        }
    }


    render() {
        let popupContent = null;

        if(this.props.type === "addDevice") {
            popupContent = <PopupAddDevice /*devices = { this.props.gateways }*/ /> ; // TODO: überarbeiten
        } else if(this.props.type === "addService") {
            popupContent = <PopupAddService devices = { this.props.devices } /> ;
        } else if(this.props.type === "addGateway") {
            popupContent = <PopupAddGateway /*devices = { this.props.gateways }*/ /> ; // TODO: überarbeiten, verfügbare Gateways aus API holen
        } else if(this.props.type === "device") {
            popupContent = <PopupDevice tagText = { this.props.tagText } token={this.props.token} changeTagText = { this.props.changeTagText } borderColor = { this.props.borderColor } changeBorderColor = { this.props.changeBorderColor } icon = { this.props.icon } changeIcon = { this.props.changeIcon } zone = { this.props.zone } uid = { this.props.uid } unitNames = { this.props.unitNames } units = { this.props.units } /> ;
        } else if(this.props.type === "gateway") {
            popupContent = <PopupGateway tagText = { this.props.tagText } token={this.props.token} changeTagText = { this.props.changeTagText } borderColor = { this.props.borderColor } changeBorderColor = { this.props.changeBorderColor } icon = { this.props.icon } changeIcon = { this.props.changeIcon } zone = { this.props.zone } uid = { this.props.uid } units = { this.props.units } /> ;
        } else if(this.props.type === "service") {  
            popupContent = <PopupService deviceNames={this.props.deviceNames} token={this.props.token} subdomainapi = {this.props.subdomainapi} subdomain = {this.props.subdomain} tagText = { this.props.tagText } changeTagText = { this.props.changeTagText } borderColor = { this.props.borderColor } changeBorderColor = { this.props.changeBorderColor } icon = { this.props.icon } changeIcon = { this.props.changeIcon } uid = { this.props.uid } units = { this.props.units } /> ;
        }
        
        
        return(
            <div className = "Popup" ref = { this.popupRef } >
                <div className = "Popup-Header">
                    <div className = "button Popup-closeButton" onClick = { this.props.deactivate }>
                        <div className="icon-close"></div>
                    </div>
                    { this.props.name }
                </div>


                { popupContent }

                { 

                    /*if(this.props.category === "gateway") {
                        <PopupGateway />
                    } else if(this.props.category === "device") {
                        <PopupDevice units = { this.props.units } /> 
                    } else if(this.props.category === "service"){
                        <PopupService units = { this.props.units } /> 
                    }*/
                }
                
            </div>
        )
    }
}

export default Popup;