import React from 'react';
import './Dots.css';

class Dots extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dotDivs: null
        };
    }

    componentDidMount() {
        console.log("did Mount");

        let divs = [];

        for(let i = 0; i < this.props.count; i++) {
            divs.push(<div key={i} className="dot"></div>); 
        }

        this.setState({
            dotDivs: divs
        });
    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    render() {

        
        return(
            <div className = "Dots">
                <div className="dots-container">
                    { this.state.dotDivs }
                </div>
            </div>
        )
    }
}

export default Dots;
