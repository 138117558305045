import React from 'react';
import './PopupDevice.css';
import './PopupAddService.css';

class PopupAddGateway extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    async componentDidMount() {
        console.log("did Mount");
        
    }

    async componentWillUnmount() {
        console.log("will Unmount");

    }


    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }


    render() {
        
        return(
            <div className = "PopupAddService">
                <div className = "PopupAddService-Items">
                    <div className = "PopupAddService-Item"> <div className = "PopupAddService-Icon">{"⚙️"}</div> <div className = "PopupAddService-DescriptionContainer"> <div className = "PopupAddService-Title">{"EFFY"}</div> <div className = "PopupAddService-Text">{<div><div>Tecomon Gateway</div> <div>(coming soon)</div></div>}</div> </div> </div>
                    <div className = "PopupAddService-Item"> <div className = "PopupAddService-Icon">{"⚙️"}</div> <div className = "PopupAddService-DescriptionContainer"> <div className = "PopupAddService-Title">{"Homematic"}</div> <div className = "PopupAddService-Text">{<div><div>Drittanbieter Gateway</div> <div>(coming soon)</div></div>}</div> </div> </div>
                </div>
            </div>
        )
    }
}

export default PopupAddGateway;
