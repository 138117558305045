import React from 'react';
import './PopupTags.css';

import { TwitterPicker } from 'react-color';

class PopupTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colorPickerActive: false,
            tagColor: this.props.borderColor ? this.props.borderColor : "var(--box-backgroundColor)",
            tagText: this.props.tagText
        };

        this.handleColorPicker = this.handleColorPicker.bind(this);
        this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleTextInput = this.handleTextInput.bind(this);

        this.pickerRef = React.createRef();
    }

    componentDidMount() {
        console.log("did Mount");    

    }

    componentWillUnmount() {
        console.log("will Unmount");

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    handleColorPicker() {
        
        this.setState({
            colorPickerActive: true,
        });

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    handleTextInput(e) {
        let text = e.target.value;

        this.setState({
            tagText: text,
        });

        this.props.changeTagText(text); //call parent function
    }
    

    handleColorPickerChange(color) {
        
        this.setState({
            tagColor: color.hex
        });

        this.props.changeBorderColor(color.hex); //call parent function (card device)
    }


    handleOutsideClick(event) {
        if(this.pickerRef.current && !this.pickerRef.current.contains(event.target)) {

            document.removeEventListener('mousedown', this.handleOutsideClick);

            this.setState({
                colorPickerActive: false,
            });
        }
    }


    render() {

        return(
            <div className = "Popup-Box-Big PopupTags">
                <div className = "PopupBox-Title">Tag</div>

                <div>
                    <div className = "PopupBox-Container">
                        <div style = {{ backgroundColor: this.state.tagColor }} className = "PopupTags-AddColor" onClick = { this.handleColorPicker }></div>
                        { this.state.colorPickerActive ? <div ref = { this.pickerRef } className = "PopupTags-ColorPicker"><TwitterPicker  onChangeComplete={ this.handleColorPickerChange } color={ this.state.tagColor } /></div> : null }
                        <div className = "PopupTags-InputText-Container"><input className = "PopupTags-InputText" type = "text" onChange={ this.handleTextInput } defaultValue={ this.state.tagText }></input></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupTags;