import React from 'react';
import './Dashboard.css';
import { jwtDecode } from 'jwt-decode';

import CardGateway from './CardGateway.js'
import CardDevice from './CardDevice.js'
import CardService from './CardService.js'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activateDashboard: this.props.activateDashboard,
            cardsGateways: [],
            cardsDevices: [],
            cardsServices: [],
            cloudBubbleRef: null, //gets updated in getDerivedStateFromProps from Main Component,
            devices: [],
        };

        this.gateways = [];
        
        this.gatewayTypes = null;
        this.fetchAllAPIs = this.fetchAllAPIs.bind(this);
        this.createCardsDevices = this.createCardsDevices.bind(this);
        

        

        this.tokenCookie = (() => {
            const name = "serviceToken=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(';');
      
            for(let cookie of cookieArray) {
              while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
              }
              if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
              }
            }
      
            // return null if the cookie is not found
            return null;
        })();

        this.token = jwtDecode(this.tokenCookie);

         
        this.fetchOptions = { //fetch options for all fetches
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.tokenCookie
            }
        };
        //this.createCards = this.createCards.bind(this);
    }



    async componentDidMount() {
        console.log("did Mount");
        this.fetchAllAPIs();

        try {
            this.props.changeGatewayCount(localStorage.getItem("gatewaycount"));
            this.props.changeDeviceCount(localStorage.getItem("devicecount"));
            this.props.changeServiceCount(localStorage.getItem("servicecount"));

           
            
        } catch (error) {
            console.error(error);
        }

        this.timerID1 = setInterval(() => this.fetchAllAPIs(), 60000);
    }

    componentWillUnmount() {
        console.log("will Unmount");

        clearInterval(this.timerID1);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return ({ 
            cloudBubbleRef: nextProps.cloudBubbleRef
        })
    }

    async fetchAllAPIs() {
        let error = false; //gatewayTypes, gateways, devices, services
        let errors = []; //errors from fetches

        await this.fetchGatewayTypes().catch((err) => { error = true; errors.push(err); });
        await this.fetchGateways().catch((err) => { error = true; errors.push(err); }); //fetch gateways after gatewayTypes, because it needs gatewayTypes
        await this.fetchDevices().catch((err) => { error = true; errors.push(err); });  //fetch devices devices after gateways, because it needs gateways
        await this.fetchServices().catch((err) => { error = true; errors.push(err); }); 
        //await this.fetchData().catch((err) => { error = true; errors.push(err); }); //fetch data last, because it needs devices
        // now in device card 

        if(error) {
            if(this.state.cloudBubbleRef !== null) {
                this.state.cloudBubbleRef.current.classList.add("Overview-Bubble-Cloud-Error");
            }

            this.props.changeGatewayCount(localStorage.getItem("gatewaycount"));
            this.props.changeDeviceCount(localStorage.getItem("devicecount"));
            this.props.changeServiceCount(localStorage.getItem("servicecount"));
            

            throw new Error("Error while fetching APIs: (" + errors + ")");
        }
    }

    createCardsGateway(names, uids) {
        let cardsGateways = [];
        
        for(let [index, name] of names.entries()) {
            cardsGateways.push(<CardGateway token={this.tokenCookie} name={name} key={index} uid={uids[index]} />);
        }

        this.setState({
            cardsGateways: cardsGateways
        });
    }
    createCardsDevices(devices) {
        let cardsDevices = [];
        
        for(let i = 0; i < devices.length; i++) {
            //cardsDevices.push(<CardDevice key={i} name={devices[i].deviceName} zone={devices[i].deviceZone} uid={devices[i].deviceUID} unitNames = { devices[i].unitNames } units={devices[i].deviceUnits} data={devices[i].deviceData} />);
            cardsDevices.push(<CardDevice key={i} token={this.tokenCookie} name={devices[i].deviceName} zone={devices[i].deviceZone} uid={devices[i].deviceUID} unitNames = { devices[i].unitNames } units={devices[i].deviceUnits} />);
        }

        this.setState({
            cardsDevices: cardsDevices
        });
    }
    createCardsServices(names, uids) {
        let cardsServices = [];
        
        for(let [index, name] of names.entries()) {
            cardsServices.push(<CardService token={this.tokenCookie} name={name} key={index} uid={uids[index]} />);
        }

        this.setState({
            cardsServices: cardsServices
        });
    }

    async fetchGateways() {    
        if(this.gatewayTypes != null) {
            
            let uids = [];
            let names = [];
            let gatewayTypes = [];

            let fetchAllGateways = async (gatewayType) => {

                //console.error("Gateway: " +gatewayType);
    
    
                await fetch(process.env['REACT_APP_' + gatewayType.toUpperCase() + '_SERVICE'] + "/gateways", this.fetchOptions)
                    .then(res => {
                        if (!res.ok) {
                            throw new Error('HTTP error, status = ' + res.status);
                        }
        
                        return res.json();
                    })
                    .then(json => {

                        uids = uids.concat(json.UIDs);
                        names = names.concat(json.names);

                        for(let i = 0; i < json.UIDs.length; i++) {
                            gatewayTypes = gatewayTypes.concat(gatewayType);
                        }
    
                        if(this.state.cloudBubbleRef.current.classList.contains("Overview-Bubble-Cloud-Error") && this.state.cloudBubbleRef !== null) {
                            this.state.cloudBubbleRef.current.classList.remove("Overview-Bubble-Cloud-Error");
                        }
                    })
                    .catch(err => {
                        console.error("Failed to fetch gateways:");
                        console.warn(err);
    
                        throw err;
                    });
            }


            let error = false;

            let callFetchAllGateways = async (gatewayType) => {
                await fetchAllGateways(gatewayType).catch((err) => { error = true; }); //TODO: error handling, auch bei gateways und services
            }

            for(let gatewayType of this.gatewayTypes) {
                await callFetchAllGateways(gatewayType);
            }
        
            this.props.changeGatewayCount(uids.length);
            localStorage.setItem("gatewaycount", uids.length);
            this.createCardsGateway(names, uids);

            this.gateways = [];
            

            for(let [index, uid] of uids.entries()) {
                this.gateways.push({ gatewayType: gatewayTypes[index], gatewayUID: uid, gatewayName: names[index] });
            }
            //console.error(gatewayTypes);

            if(error) {
                throw new Error("Error while fetching gateways");
            }
        }
    }

    async fetchDevices() {
        let options = {...this.fetchOptions};
        options.method = 'POST';

        let count = 0;
        let names = [];
        let UIDs = [];
        let units = [];
        let unitNames = [];
        let zones = [];



        let fetchGatewayDevices = async (gateway) => {

            //console.error("Gateway: " + gateway.gatewayType);


            await fetch(process.env['REACT_APP_' + gateway.gatewayType.toUpperCase() + '_SERVICE'] + "/devices", options)
                .then(res => {
                    if (!res.ok) {
                        throw new Error('HTTP error, status = ' + res.status);
                    }
    
                    return res.json();
                })
                .then(json => {

                    count += json.UIDs.length;

                    names = names.concat(json.names);
                    UIDs = UIDs.concat(json.UIDs);
                    units = units.concat(json.units);
                    unitNames = unitNames.concat(json.unitNames);
                    zones = zones.concat(json.zones);

                    console.error(json);
                    //console.error("Devices fetched of " + gateway.gatewayType);


                    if(this.state.cloudBubbleRef.current.classList.contains("Overview-Bubble-Cloud-Error") && this.state.cloudBubbleRef !== null) {
                        this.state.cloudBubbleRef.current.classList.remove("Overview-Bubble-Cloud-Error");
                    }
                })
                .catch(err => {

                    console.error("Failed to fetch devices:");
                    console.warn(err);

                    throw err;
                });
        }


       


        if(this.gateways != null) {
            let error = false;


            let callFetchGatewayDevices = async (gateway) => {
                await fetchGatewayDevices(gateway).catch((err) => { error = true; });  //TODO: error handling, auch bei gateways und services
            }
            

            for(let gateway of this.gateways) {
                options.body = JSON.stringify({ "gatewayUID": gateway.gatewayUID });

                //console.error("Fetch now: " +gateway.gatewayType);


                await callFetchGatewayDevices(gateway);
            }
            

            this.props.changeDeviceCount(count);
            localStorage.setItem("devicecount", count);


            //this.devices = [];
            /*let deviceDataEmpty = true;

            if(this.state.devices.length > 0) {
                deviceDataEmpty = "deviceData" in this.state.devices[0] ? false : true;
            }*/

            let devices = [];



            // new: add Tecomon Account section -----------------------------------------------------

            let accountDeviceUIDs = [];



            await fetch(process.env.REACT_APP_PORTALDB_SERVICE + "/service?uid=TS.000000000000", this.fetchOptions)
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }

                return res.json();
            })
            .then(json => {

                for(let device of json.devices) {
                    accountDeviceUIDs.push(device.UID);
                }

            })
            .catch(err => {

                console.error("Failed to save popup settings");
                console.warn(err);

                throw err;
            });




            if(this.token.user_id === "16") {
                for(let [index, uid] of UIDs.entries()) { 

                        if(accountDeviceUIDs.includes(uid)) {
                            devices.push({ deviceUID: uid, deviceName: names[index], deviceUnits: units[index], unitNames: unitNames[index], deviceZone: zones[index] });
                        }
                    }
            } else {

                for(let [index, uid] of UIDs.entries()) { // TODO effizienter machen

                    devices.push({ deviceUID: uid, deviceName: names[index], deviceUnits: units[index], unitNames: unitNames[index], deviceZone: zones[index] });
                    
                    
                    /*if(!deviceDataEmpty) {
                        devices.push({ deviceUID: uid, deviceName: names[index], deviceUnits: units[index], unitNames: unitNames[index], deviceZone: zones[index], deviceData: this.props.devices[index].deviceData })
                    } else {
                        devices.push({ deviceUID: uid, deviceName: names[index], deviceUnits: units[index], unitNames: unitNames[index], deviceZone: zones[index], deviceData: [] 
                    }*/
                }
            }   

            // new: add Tecomon Account section -----------------------------------------------------



            this.setState({
                //devices: [...prevState.devices, { deviceUID: uid, deviceName: names[index], deviceUnits: units[index], deviceZones: zones[index], deviceData: prevState.devices[index].deviceData } ]
                devices: devices
            });
            this.props.setDevices(devices);
            this.createCardsDevices(devices);

            if(error) {
                throw new Error("Error while fetching devices");
            }
        }
    }


    async fetchServices() {

        await fetch(process.env.REACT_APP_PORTALDB_SERVICE  + "/userservice", this.fetchOptions /*credentials: 'include' */)
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }

                return res.json();
            })
            .then(json => {
                
                let names = [];
                let UIDs = [];
                

                for(let service of json) {
                    names.push(service.name);
                    UIDs.push(service.uid);
                }

                this.createCardsServices(names, UIDs);

                this.props.changeServiceCount(json.length);
                localStorage.setItem("servicecount", json.length);

                if(this.state.cloudBubbleRef.current.classList.contains("Overview-Bubble-Cloud-Error") && this.state.cloudBubbleRef !== null) {
                    this.state.cloudBubbleRef.current.classList.remove("Overview-Bubble-Cloud-Error");
                }
            })
            .catch(err => {

                console.error("Failed to fetch services:");
                console.warn(err);

                throw err;
            });
    }


    async fetchGatewayTypes() {
        await fetch(process.env.REACT_APP_PORTAL_SERVICE  + "/gateways", this.fetchOptions)
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }

                return res.json();
            })
            .then(res => {
                this.gatewayTypes = res.gateways;

                localStorage.setItem("gateways", res.gateways);

                if(this.state.cloudBubbleRef.current.classList.contains("Overview-Bubble-Cloud-Error") && this.state.cloudBubbleRef !== null) {
                    this.state.cloudBubbleRef.current.classList.remove("Overview-Bubble-Cloud-Error");
                }
            })
            .catch(err => {

                console.error("Failed to fetch gateway types:");
                console.warn(err);

                throw err;
            });
    }



    render() {
        //console.error("Devices: " +this.props.devices);

        if(this.state.activateDashboard === true) {

            return(
                <div className = "Dashboard">
                    <h2>Dashboard</h2>

                    <h3>Gateways</h3>
                    <div className = "GatewaysDash">
                        { this.state.cardsGateways.length === 0 ? <p>Keine Gateways vorhanden</p> : this.state.cardsGateways }
                    </div>

                    <h3>Devices</h3>
                    <div className = "GatewaysDash">
                        { this.state.cardsDevices.length === 0 ? <p>Keine Geräte vorhanden</p> : this.state.cardsDevices }
                    </div>

                    <h3>Services</h3>
                    <div className = "GatewaysDash">
                        { this.state.cardsServices.length === 0 ? <p>Keine Services vorhanden</p> : this.state.cardsServices }
                    </div>
                </div>
            )    
        }
    }
}

export default Dashboard;