import React from 'react';
import './PopupDevice.css';
import './PopupAddService.css';

class PopupAddService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            configService: false,
            configServiceUID: null,
            configDevices: [],
            selectedDevices: []
        };

        this.clickService = this.clickService.bind(this);
        this.addService = this.addService.bind(this);
        this.closeConfig = this.closeConfig.bind(this);
        this.selectDevice = this.selectDevice.bind(this);
    }

    async componentDidMount() {
        console.log("did Mount");

        await this.fetchServices();
        
    }

    async componentWillUnmount() {
        console.log("will Unmount");

        
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }


    async fetchServices() {

        await fetch(process.env.REACT_APP_PORTAL_SERVICE  + "/services", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            }})
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }

                return res.json();
            })
            .then(json => {

                let services = [];

                for(let [index, name] of json.names.entries()) {
                    services.push({"icon": json.icon[index], "name": name, "UID": json.UIDs[index], "desc": json.description[index], "units": json.units[index], "subdomain": json.subdomain[index], "subdomainapi": json.subdomainapi[index]});
                }

                this.setState({
                    services: services
                });
                

                /*if(this.state.cloudBubbleRef.current.classList.contains("Overview-Bubble-Cloud-Error") && this.state.cloudBubbleRef !== null) {
                    this.state.cloudBubbleRef.current.classList.remove("Overview-Bubble-Cloud-Error");
                }*/
            })
            .catch(err => {

                console.error("Failed to fetch services:");
                console.warn(err);

                throw err;
            });
    }


    clickService(evt) {
        let serviceUID = evt.target.closest('[data-uid]').getAttribute("data-uid");
        //console.error(this.props.devices);
        //console.error(this.state.services);
        
        for(let device of this.props.devices) {
            
            let service = this.state.services.find(service => service.UID === serviceUID);
            

            if(device.deviceUnits.includes(...service.units)) {
                let unitIndex = []; 
                
                for(let unit of service.units) {
                    unitIndex.push(device.deviceUnits.indexOf(unit));
                }

                this.state.configDevices.push({ deviceUID: device.deviceUID, unitIndex: unitIndex, deviceName: device.deviceName });
            } 
        }

        this.setState({
            configService: true,
            configServiceUID: serviceUID
        });
    }

    async addService() {
        let service = this.state.services[this.state.services.findIndex(element => element.UID === this.state.configServiceUID)];

        await fetch(process.env.REACT_APP_PORTALDB_SERVICE  + "/service", { 
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
            body: JSON.stringify({ "uid": this.state.configServiceUID, "devices": this.state.selectedDevices, "emoji": service.icon, "name": service.name, "subdomain": service.subdomain, "subdomainapi": service.subdomainapi, "units": service.units })
        })
        .then(res => {
            if (!res.ok) {
                throw new Error('HTTP error, status = ' + res.status);
            }

            //set cookie for access of service
            let currentDate = new Date();
            let expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
            
            if(process.env.NODE_ENV === 'production') {
                document.cookie = "serviceToken="+sessionStorage.getItem("token")+"; expires="+expirationDate+"; domain=.tecomon.net; path=/";
            } else {
                document.cookie = "serviceToken="+sessionStorage.getItem("token")+"; expires="+expirationDate+"; domain=localhost; path=/";
            }
            
            //console.error(document.cookie);

            this.closeConfig();
        })
        .catch(err => {

            console.error("Failed to add service");
            console.warn(err);

            throw err;
        });
    }

    
    closeConfig() {
        this.setState({
            configService: false,
            configServiceUID: null,
            configDevices: [],
            selectedDevices: []
        });
    }

    selectDevice(evt) {
        let deviceUID = evt.target.closest('[data-uid]').getAttribute("data-uid");
        let deviceName = evt.target.closest('[data-name]').getAttribute("data-name");
        let deviceUnits = evt.target.closest('[data-units]').getAttribute("data-units");

        let newSelectedDevices = this.state.selectedDevices;

        
        if(evt.target.checked) {
            newSelectedDevices.push({ "UID": deviceUID, "name": deviceName, "units": JSON.parse(deviceUnits) }); //select device

            this.setState({
                selectedDevices: newSelectedDevices
            });
        } else {
            let index = this.state.selectedDevices.findIndex(obj => obj.uid === deviceUID);

            if (index !== -1) {
            
                newSelectedDevices.splice(index, 1) //unselect device
            }


            this.setState({
                selectedDevices: newSelectedDevices
            });
        }
    }



    render() {
        //console.error("TEST: " + this.props.data);
        let divData = [];
        let configService = null;

        for(let [index, service] of this.state.services.entries()) {
            divData.push(<div key={index} data-uid = {service.UID} onClick = { this.clickService } className = "PopupAddService-Item"> <div className = "PopupAddService-Icon">{service.icon}</div> <div className = "PopupAddService-DescriptionContainer"> <div className = "PopupAddService-Title">{service.name}</div> <div className = "PopupAddService-Text">{service.desc}</div> <div className = "button PopupDevice-unitButtons">{service.units}</div></div> </div>)
        }


        const divsServices = divData.map((data, index) => (
            <div key={index} className = "fade-in active">
                <div>{data}</div>
            </div>
        ));

        if (this.state.configService)   {
            configService = this.state.services.find(service => service.UID === this.state.configServiceUID);
        }

        
        return(
            <div className = "PopupAddService">

                { this.state.configService ? (

                    <div className = "PopupAddService-ConfigService">
                        <div className = "PopupDevice-Left">
                            <div data-uid = {configService.UID} className = "PopupAddService-Item"> 

                                <div className = "PopupAddService-DescriptionContainer"> 
                                    <div className = "PopupAddService-Icon">
                                        {configService.icon}
                                    </div> 
                                    <div className = "PopupAddService-Title">{configService.name}</div> 
                                    <div className = "PopupAddService-Text">{configService.desc}</div> 
                                    <div className = "button PopupDevice-unitButtons">{configService.units}</div>
                                </div> 

                            </div>
                        </div>
                        <div className = "PopupDevice-Right PopupAddService-Right">

                            <div className = "PopupAddService-DeviceItem-List">
                                { 
                                    this.state.configDevices.map((data, index) => (
                                        <div key={index} className = "PopupAddService-DeviceItem" data-uid= {data.deviceUID} data-units= {JSON.stringify(data.unitIndex)} data-name= {data.deviceName}> <input onChange = { this.selectDevice }  className="PopupAddService-DeviceCheckbox" type="checkbox"></input> 
                                            <div>
                                                { data.deviceName } 
                                            </div>
                                        </div>
                                    ))
                                } 
                               
                                <div className = "PopupAddService-menuButtons button" onClick={ this.closeConfig }> Back </div>
                                <div className = "PopupAddService-menuButtons button" onClick={ this.addService }> Add </div>
                            </div>
                        </div>
                    </div>

                ) : (

                    <div className = "PopupAddService-Items">
                        { divsServices } 
                    </div>

                )}
            </div>
        )
    }
}

export default PopupAddService;
