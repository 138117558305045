import React from 'react';
import './PopupDevice.css';
import './PopupAddService.css';
import './PopupAddDevice.css';

class PopupAddDevice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    async componentDidMount() {
        console.log("did Mount");
        
    }

    async componentWillUnmount() {
        console.log("will Unmount");

    }


    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }


    render() {
        
        return(
            <div className = "PopupAddDevice">
                <div className = "PopupAddService-Items">
                    <div className = "PopupAddDevice-Box"> <div className = "PopupAddService-Icon">{"⚙️"}</div> <div className = "PopupAddService-DescriptionContainer"> <div className = "PopupAddService-Title">{"coming soon"}</div> <div className = "PopupAddService-Text">{<div><div>Add devices, even without a gateway connection, to manage the device, add icons, tags, zones, and an image of the QR-code or serial number.</div></div>}</div> </div> </div>
                </div>
            </div>
        )
    }
}

export default PopupAddDevice;
