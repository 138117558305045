import React from 'react';
import './Card.css';

import Popup from './Popup.js';

class CardGateway extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupActive: false,
            emojiIcon: null,
            emojiBorderColor: "var(--box-backgroundColor)",
            tagText: "",
        };

        this.loadPopup = this.loadPopup.bind(this);
        this.unloadPopup = this.unloadPopup.bind(this);
        this.changeEmojiIcon = this.changeEmojiIcon.bind(this);
        this.changeBorderColor = this.changeBorderColor.bind(this);
        this.changeTagText = this.changeTagText.bind(this);
        this.loadSettings = this.loadSettings.bind(this);
    }

    async componentDidMount() {
        console.log("did Mount");

        await this.loadSettings();
    }

    async loadSettings() {
        await fetch(process.env.REACT_APP_PORTALDB_SERVICE + "/gateway?uid=" + this.props.uid, {  method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
        }
        } /*credentials: 'include' */)
        .then(res => {
            if (!res.ok) {
                throw new Error('HTTP error, status = ' + res.status);
            }

            return res.json();
        })
        .then(json => {
            //console.log(json);

            this.setState({
                emojiIcon: json.emoji,
                tagText: json.tagText,
                emojiBorderColor: json.tagColor
            });
        })
        .catch(err => {

            console.error("Failed to load popup settings");
            console.warn(err);

            //throw err;
        });

    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    loadPopup() {
        console.log("load Popup");

        this.setState({
            popupActive: true
        });
    }
    unloadPopup() {
        console.log("unload Popup");

        this.setState({
            popupActive: false
        });
    }

    changeEmojiIcon(icon) {
        this.setState({
            emojiIcon: icon
        });
    }

    changeBorderColor(color) {
        this.setState({
            emojiBorderColor: color
        });
    }

    changeTagText(text) {
        this.setState({
            tagText: text
        });
    }

    render() {
        
        return(
            <div>
                <div className = "Card" onClick = { this.loadPopup }>
                    <div className = "Card-Header">{this.props.name}</div>

                    <br></br>
                    <br></br>

                    <div style = {{ borderColor: this.state.emojiBorderColor }} className="PopupDevice-EmojiIcon-Container">
                        { this.state.emojiIcon ? 
                            <div className = "PopupDevice-EmojiIcon"> 
                                { this.state.emojiIcon } 
                            </div>  
                            :  
                            <div className = "PopopDevice-Icon-Placeholder">
                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17C14.2091 17 16 15.2091 16 13H8C8 15.2091 9.79086 17 12 17Z" fill="currentColor" />
                                    <path d="M10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10Z" fill="currentColor" />
                                    <path d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z" fill="currentColor" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="currentColor" />
                                </svg>
                            </div>
                        }
                    </div>

                </div>
                

                { this.state.popupActive ? <Popup type = { "gateway" } tagText = { this.state.tagText } changeTagText = { this.changeTagText } borderColor = { this.state.emojiBorderColor } changeBorderColor = { this.changeBorderColor } icon = { this.state.emojiIcon } changeIcon = { this.changeEmojiIcon } category = { "device" } uid = { this.props.uid } name = { this.props.name } deactivate = { this.unloadPopup } /> : null }

            </div>
        )
    }
}

export default CardGateway;