import React from 'react';
import './Footer.css';

//import Popup from './Popup.js';


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        console.log("did Mount");
    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    render() {

        
        return(
            <div className = "Footer">
                <div>© Tecomon GmbH 2023, alle Rechte vorbehalten | Technology made with ♡ in Germany</div>

                <div className = "Footer-Menu">
                    <div onClick={() => window.open("https://tecomon.de/impressum/", "_blank")}>Impressum</div>
                    <div onClick={() => window.open("https://tecomon.de/wp-content/uploads/agb/AllgemeineGeschaeftsbedingungenTecomonGmbH_06_2020.png", "_blank")}>AGB</div>
                    <div onClick={() => window.open("https://tecomon.de/datenschutz/", "_blank")}>Datenschutz</div>
                </div>
            </div>
        )
    }
}

export default Footer;