import React from 'react';
import './PopupDevice.css';

import EmojiPicker from 'emoji-picker-react';
import PopupText from './PopupText';
import PopupTags from './PopupTags';

class PopupDevice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emojiPickerActive: false,
            emojiIcon: this.props.icon,
            tagText: this.props.tagText,
            borderColor: this.props.borderColor,
            deviceData: [],
            unitDivs: null,
            timestamp: null,
        };

        this.saveSettings = this.saveSettings.bind(this);
        this.handleEmojiPicker = this.handleEmojiPicker.bind(this);
        this.handleTagColor = this.handleTagColor.bind(this);
        this.handleTagText = this.handleTagText.bind(this);
    }

    async componentDidMount() {
        console.log("did Mount");

        if(this.props.units !== undefined) {
            await this.getDeviceData();
            this.timerID1 = setInterval(() => this.getDeviceData(), 60000); 


            let divArray = [];
        
            for (let unit of this.props.units) {
                divArray.push(<div className = "button PopupDevice-unitButtons">{ unit }</div>);
            }

            this.setState({
                unitDivs: divArray
            });
        }
        
        //await this.loadSettings();
    }

    async componentWillUnmount() {
        console.log("will Unmount");

        await this.saveSettings();
        clearInterval(this.timerID1);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }


    async getDeviceData() {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
            },
        };
      
        let uidArray = this.props.uid.split(".");
        let gateway = null;

        if(uidArray[0] === "HO") {
            gateway = "HOMEMATIC";
        } else {
            gateway = "EFFY";
        }

        await fetch(process.env["REACT_APP_"+ gateway +"_SERVICE"] + "/devicedata?count=1&uid=" + this.props.uid, options)
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }

                return res.json();
            })
            .then(json => {
                console.error(json);
                console.error(json.unitData);
         
                this.setState({
                    deviceData: json.unitData,
                    timestamp: json.timestamps[0]
                });
            })
            .catch(err => {

                console.error("Failed to fetch devicedata:");
                console.error(err);
            });
    }


    async handleEmojiPicker(e) {
        
        if(this.state.emojiPickerActive === true) {
            this.setState({
                emojiPickerActive: !this.state.emojiPickerActive,
                emojiIcon: e.emoji
            });

            this.props.changeIcon(e.emoji);

        } else {
            this.setState({
                emojiPickerActive: !this.state.emojiPickerActive
            });
        }
    }


    async handleTagColor(color) {
        
        this.setState({
              
            borderColor: color
        });

        this.props.changeBorderColor(color);
    }

    async handleTagText(text) {
   
        this.setState({
            tagText: text
        });

        this.props.changeTagText(text);
    }



    async saveSettings() {

            await fetch(process.env.REACT_APP_PORTALDB_SERVICE + "/device", {  method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.token
                },
                body: JSON.stringify({ 
                    "uid": this.props.uid,
                    "emoji": this.state.emojiIcon,
                    "tagColor": this.state.borderColor,
                    "tagText": this.state.tagText,
                })
            } /*credentials: 'include' */)
            .then(res => {
                if (!res.ok) {
                    throw new Error('HTTP error, status = ' + res.status);
                }
            })
            .catch(err => {

                console.error("Failed to save popup settings");
                console.warn(err);

                //throw err;
            });
    }


    render() {
        //console.error("Device Data: " + this.props.data);
        let dataBoxes = [];

        function removeDecimalPlaces(number, places) {
            var multiplier = Math.pow(10, places);
            var result = Math.floor(number * multiplier) / multiplier;
            return result;
        }

        for(let [index, data] of this.state.deviceData.entries()) {
            let value = null;

            if((data.toString().split('.')[1] || '').length > 4) {
                value = removeDecimalPlaces(data, 4);
            } else {
                value = data;
            }

            dataBoxes.push(<PopupText key={index} title = { this.props.unitNames[index] } text = { value } />);
        }

        
        let date = new Date(this.state.timestamp);
        let dateString = date.toLocaleString();

        dataBoxes.push(<PopupText key={this.state.deviceData.length} title = { "Timestamp" } text = { dateString } />);


        
        return(
            <div className = "PopupDevice">
                <div className = "PopupDevice-Left">
                    <div>
                        <div onClick = { this.handleEmojiPicker } style = {{ borderColor: this.props.borderColor }} className="PopupDevice-EmojiIcon-Container">
                            { this.state.emojiIcon ?  
                                <div className = "PopupDevice-EmojiIcon"> { this.state.emojiIcon } </div>  
                                :  
                                <div className = "PopopDevice-Icon-Placeholder">
                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17C14.2091 17 16 15.2091 16 13H8C8 15.2091 9.79086 17 12 17Z" fill="currentColor" />
                                    <path d="M10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10Z" fill="currentColor" />
                                    <path d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z" fill="currentColor" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="currentColor" />
                                </svg>
                            </div>
                            }
                        </div>
                        { this.state.emojiPickerActive ?  <div className = "PopupDevice-EmojiPicker"><EmojiPicker onEmojiClick = { this.handleEmojiPicker } height = { "100%" } width = { "100%" } /></div> : null }
                    </div>

                    { this.state.unitDivs ? <div className = "PopupDevice-unitButtonsContainer"> { this.state.unitDivs } </div> : null }
                </div>

                <div className = "PopupDevice-Right">
                    <PopupText title = { "UID" } text = { this.props.uid } />
                    <PopupText title = { "Zone" } text = { this.props.zone } />
                    <PopupTags borderColor = { this.state.borderColor } tagText = { this.state.tagText } changeTagText = { this.handleTagText } changeBorderColor = { this.handleTagColor } />
                    { dataBoxes }
                </div>
                
            </div>
        )
    }
}

export default PopupDevice;
